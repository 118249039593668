<template>
	<div class="form-list">
		<div class="form-item">
			<span class="hint">當前密碼</span>
			<input type="password" class="value" v-model="oldPwd">
		</div>
		<div class="form-item">
			<span class="hint">新密碼</span>
			<input type="password" class="value" v-model="newPwd">
		</div>
		<div class="form-item">
			<span class="hint">確認新密碼</span>
			<input type="password" class="value" v-model="confirmPwd">
		</div>
		<div class="btn-update" @click="updatePwd"><div class="lds-ring1" v-if="loading"><div></div><div></div><div></div><div></div></div>{{loading ? '正在提交...' : '保存修改'}}</div>
	</div>
</template>

<script>
	import update_pwd from '@/views/mixins/update_pwd'
	export default {
		mixins: [
			update_pwd
		]
	}
</script>

<style lang="scss" scoped>
	.form-list {
		display: flex;
		flex-direction: column;
		padding-top: 16px;
	}

	.form-list > .form-item {
		display: flex;
		flex-direction: row;
		background-color: #fff;
		align-items: center;
		padding: 0px 24px 0px 24px;
		position: relative;
	}

	.form-list > .form-item > .hint {
		color: #969696;
		font-size: 28px;
		font-weight: 400;
		position: absolute;
		left: 24px;
		top: 50%;
		transform: translateY(-50%);
	}

	.form-list > .form-item > .value {
		flex: 1;
		height: 97px;
		color: #231F20;
		font-weight: bold;
		font-size: 32px;
		text-align: right;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
		align-items: center;
		box-sizing: border-box;
		position: relative;
		background: transparent;
		border-bottom: 1px solid #b7b7b7;
	}

	.form-list > .form-item:nth-child(3) > .value {
		border-bottom: none;
	}

	.form-list > .btn-update {
		width: 420px;
		height: 88px;
		border-radius: 44px;
		border: 1px solid #36C4D0;
		color: #36C4D0;
		font-size: 34px;
		font-weight: bold;
		margin: 350px auto 0 auto;
		background-color: #EDEDED;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
	}

	.form-list > .btn-update:active {
		opacity: 7;
	}

	/** loading start */
    .lds-ring1 {
        display: inline-block;
        position: relative;
        width: 42px;
        height: 42px;
		margin-right: 20px;
    }
    
    .lds-ring1 div {
        box-sizing: border-box;
        display: block;
        position: absolute;
        width: 42px;
        height: 42px;
        margin: 4px;
        border: 4px solid #36C4D0;
        border-radius: 50%;
        animation: lds-ring1 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
        border-color: #36C4D0 transparent transparent transparent;
    }
    
    .lds-ring1 div:nth-child(1) {
        animation-delay: -0.45s;
    }
    
    .lds-ring1 div:nth-child(2) {
        animation-delay: -0.3s;
    }
    
    .lds-ring1 div:nth-child(3) {
        animation-delay: -0.15s;
    }

    @keyframes lds-ring1 {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    /** loading end */ 
</style>
